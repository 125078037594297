/**
 * Indicates if value in correct in terms of HTML heading levels
 * @param {string|number} value - input value to be checked
 * @returns {boolean} true if value is a correct HTML heading level otherwise false
 */
function validateHeaderLayout(value: string | number): boolean {
  return [1, 2, 3, 4, 5, 6].includes(Number.parseInt(value))
}

/**
 * Returns the name of HTML tag to use for heading (h1-h6 or tag defined as a fallback)
 * @param {string|number} headerLayout - prop from TYPO3 API
 * @param {string} fallback - tag to be returned if `headerLayout` has incorrect value
 * @returns {string} the name of HTML tag
 */
function setHeaderTag(headerLayout: string | number, fallback = 'p'): string {
  if (validateHeaderLayout(headerLayout)) {
    return `h${headerLayout}`
  }
  return fallback
}

export default setHeaderTag
